import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

const AboutPage = () => (
  <>
    <SEO title="About" />
    <h1>About Me</h1>
    <p>
      This is the about page. There could be some text here that describes the
      website.
    </p>
    <p>Or whatever.</p>
    <h3>
      <Link to="/tags">Try Searching by Tags</Link>
    </h3>
  </>
)

export default AboutPage
